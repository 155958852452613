import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import MainPage from "./components/view/MainPage.jsx";
import ScreenSettings from "./components/settings/ScreenSettings.jsx";
import reportWebVitals from "./reportWebVitals.js";
import { BrowserRouter } from "react-router-dom";
import { SharedStateProvider } from "./SharedContext.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SharedStateProvider>
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
      <ScreenSettings />
    </SharedStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
